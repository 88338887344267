.Hr {
  width: 130%;
  overflow: hidden;
  padding: 15rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: url("../../../public/assets/hr.png");
  background-repeat: no-repeat;
  margin-left: 80%;
  .hr_text {
    width: 120rem;
    margin-right: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    background-color: rgba(9, 26, 54, 0.3);
    border-radius: 20px;
    h2 {
      margin-bottom: 1rem;
    }
    p {
      font-weight: 600;
      line-height: 2rem;
    }
  }
}

@media only screen and (max-width: 920px) {
  .Hr {
    width: 140%;
    background-size: auto;
    padding: 1rem;
    .hr_text {
      width: 100%;
      margin-left: 1rem;
      margin-right: 60%;
    }
  }
}
