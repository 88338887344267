.Contact{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #061022;
    padding: 2rem;
    img{
        width: 50%;
        border-radius: 2rem;
    }
    .contactInfo{
        margin-top: 7rem;
        background-color: #061022;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        color: white;
        gap: 1rem;
        text-align: center;
        line-height: 2rem;
        .contactText{
            margin-top: 2rem;
        }
        .location{
            width: 100%;
            border-radius: 2rem;
        }
        h2{
            margin-bottom: 1rem;
        }
        p{
            margin-bottom: 1rem;
        }
    }
}


@media only screen and (max-width: 920px){
    .Contact{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #061022;
        padding: 2rem;
        img{
            width: 50%;
            border-radius: 2rem;
        }
        .contactInfo{
            margin-top: 7rem;
            background-color: #061022;
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 2rem;
            color: white;
            gap: 1rem;
            text-align: center;
            line-height: 2rem;
            .contactText{
                margin-top: 2rem;
                h2{
                    margin-bottom: 1rem;
                }
                p{
                    margin-bottom: 1rem;
                }
            }
            .location{
                width: 100%;
                height: 80%;
                border-radius: 2rem;
            }
        }
    }
}