footer{
    width: 100%;
    background-color: #061022;
    padding: 1rem;
    position: relative;
    color: white;
    -webkit-box-shadow: 2px -5px 52px -35px rgba(255,238,0,1);
-moz-box-shadow: 2px -5px 52px -35px rgba(255,238,0,1);
box-shadow: 2px -5px 52px -35px rgba(255,238,0,1);
    .upFooter{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        .footerContact{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 1rem;
            img{
                width: 10rem;
            }
        }
        .footerInfo{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 1rem;
        }
        .footerMenu{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            ul{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                li{
                    a{
                        color: white;
                    }
                    a:hover{
                      color: #f3c623;
                    }
                }
            }
        }
    }
    .downFooter{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        padding: 1rem;
        p{
            font-size: 12px;
        }
    }
}