.Sec2{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    .sec2Left{
        background-color: #f3c623;
        width: 50%;
        color: #091a36;
        padding: 2rem;
        border-radius: 2rem;
        margin-left: 5%;
        // box-shadow: 7px 4px 4px grey;
        -webkit-box-shadow: -2px -2px 35px 11px rgba(0,0,0,0.6);
        -moz-box-shadow: -2px -2px 35px 11px rgba(0,0,0,0.6);
        box-shadow: -2px -2px 35px 11px rgba(0,0,0,0.6);
        animation-name: floatIt1;
        animation-iteration-count: infinite;
        animation-duration: 4000ms;
        animation-timing-function: linear;
        animation-direction: alternate;
        ul{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 1.5rem;
            li{
                display: flex;
                align-items: center;
                gap: 2rem;
                font-weight: 700;
                font-style: italic;
              svg{
                    font-size: 4rem;
                    fill: #091a36;
                }
            }
        }
        
    }
    .sec2Right{
        background-color: #091a36;
        color: white;
        width: 50%;
        margin-left: -10%;
        padding: 4rem;
        border-radius: 1rem;
        // box-shadow: 7px 4px 4px grey;
        -webkit-box-shadow: -2px -2px 35px 11px rgba(0,0,0,0.6);
-moz-box-shadow: -2px -2px 35px 11px rgba(0,0,0,0.6);
box-shadow: -2px -2px 35px 11px rgba(0,0,0,0.6);
        h1{
            font-weight: 800;
            margin-bottom: 0.5rem;
        }
        p{
            font-weight: 900;
        }
        img{
            width: 7rem;
        }
        animation-name: floatIt2;
        animation-iteration-count: infinite;
        animation-duration: 9000ms;
        animation-timing-function: linear;
        animation-direction: alternate;
    }
    
}

@keyframes floatIt {
    0% {
        translate: 0 0;
    }

    50% {
        translate: 5% 5%;
    }

    100% {
        translate: 0 0;
    }
}

@keyframes floatIt2 {
    0% {
        translate: 0 0;
    }

    50% {
        translate: -8% -8%;
    }

    100% {
        translate: 0 0;
    }
}



@media only screen and (max-width: 920px){
    .Sec2{
        display: flex;
        flex-direction: column;
    }

}