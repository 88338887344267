*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul{
  list-style: none;
}

a{
  text-decoration: none;
}

a:hover{
  cursor: pointer;
  transition: .4s;
}

/* ----------------------Scrollbar-------------------------------------------------------- */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #061022;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(6, 16, 34, 0.5);
}