.Fair {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  svg {
    fill: #091a36;
    font-size: 5rem;
  }
  h1 {
    margin: 1rem;
    color: #091a36;
  }
  video {
    width: 90%;
    border-radius: 20px;
  }
}

@media only screen and (max-width: 920px) {
  .Fair {
    video {
      width: 100%;
    }
  }
}
