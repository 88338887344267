.Sec3 {
  width: 100%;
  gap: 4rem;
  margin-top: 1rem;
  video {
    opacity: 0.5;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .Sec3-wrapper {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;

    .gear {
      width: 5rem;
      animation: rotate 5s linear infinite;
      background-color: #091a36;
      padding: 1rem;
      border-radius: 100%;
    }
    .partnerInfo {
      background-color: #091a36;
      width: 100%;
      color: white;
      padding: 1rem;
      border-radius: 10px;
      text-align: center;
      -webkit-box-shadow: -2px -2px 35px 11px rgba(0, 0, 0, 0.6);
      -moz-box-shadow: -2px -2px 35px 11px rgba(0, 0, 0, 0.6);
      box-shadow: -2px -2px 35px 11px rgba(0, 0, 0, 0.6);
      svg {
        font-size: 5rem;
        fill: white;
      }
    }
    .partnerLogos {
      width: 80%;
      .slick-slider {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .LazyLoad {
            width: 100%;
            img {
              width: 80%;
            }
          }
        }
      }
    }
    p {
      color: #091a36;
      border-radius: 1rem;
      text-align: center;
      margin-bottom: 2rem;
      font-weight: 700;
      font-style: italic;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
