.Hakkimizda{
    width: 100%;
    height: 98vh;
    margin-top: 3rem;
    img{
        width: 100%;
        margin-top: 2rem;
        display: relative;
    }
    
    .aboutText{
        position: absolute;
        margin-top: -55%;
        width: 50%;
        padding-left: 5rem;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        text-align: center;
        p{
            font-size: 16px;
            line-height: 2rem;
        }
        .karrolLogo{
            width: 20%;
        }
    }
    
}

@media only screen and (max-width: 920px){
    .Hakkimizda{
        width: 100%;
        height: max-content;
        margin-bottom: -1rem;
        img{
            width: 100%;
            margin-top: 2rem;
        }
        .aboutText{
            position: absolute;
            margin-top: -60%;
            width: 50%;
            padding-left: 1rem;
            gap: 0.5rem;
            font-size: smaller;
            h1{
                font-size: 12px;
                margin-left: 10%;
            }
            p{
               font-size: 10px;
               line-height: 1rem;
            }
            .karrolLogo{
                width: 20%;
            }
        }
    }
}