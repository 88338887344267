.AnaMarkalar {
  width: 100%;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(6, 16, 34);
  color: white;
  padding: 6rem;
  gap: 1rem;
  .mainLogos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    img {
      width: 10%;
    }
  }
  .anaText {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;

    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (max-width: 920px) {
  .AnaMarkalar {
    width: 100%;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .anaText {
      .karrolLogo {
        margin-top: 2rem;
        width: 40%;
        height: unset;
      }
      width: 100%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 2rem;
      background-color: rgb(6, 16, 34);
      color: white;
      p {
        font-weight: 600;
        line-height: 1.5rem;
        margin-bottom: 1rem;
      }
    }
  }
}
