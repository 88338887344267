/* LoadingPage.css */
.Loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  background-color: #061022;
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    $animation-delay: 0.1s; // Adjust the delay between each letter

    .k,
    .a,
    .rr,
    .o,
    .l {
      width: 20%;
      opacity: 0;
      animation: draw 0.5s forwards;
    }

    .k {
      animation-delay: $animation-delay * 1;
    }

    .a {
      animation-delay: $animation-delay * 2;
    }

    .rr {
      animation-delay: $animation-delay * 3;
    }

    .o {
      animation-delay: $animation-delay * 4;
    }

    .l {
      animation-delay: $animation-delay * 5;
    }
  }
}

@keyframes draw {
  to {
    opacity: 1;
  }
}
