.AfterMarketler {
  width: 100%;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(6, 16, 34);
  color: white;
  padding: 6rem;
  gap: 2rem;
  .partnersLogos {
    border-radius: 10px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    &:nth-of-type(1) {
      img {
        animation-name: floatIt2;
        animation-iteration-count: infinite;
        animation-duration: 9000ms;
        animation-timing-function: linear;
        animation-direction: alternate;
      }
    }
    &:nth-of-type(2) {
      img {
        animation-name: floatIt;
        animation-iteration-count: infinite;
        animation-duration: 9000ms;
        animation-timing-function: linear;
        animation-direction: alternate;
      }
    }
    img {
      width: 7%;
    }
  }
  .afterText {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;

    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }
  }
}

@keyframes floatIt {
  0% {
    translate: 0 0;
  }

  50% {
    translate: 10% 10%;
  }

  100% {
    translate: 0 0;
  }
}

@keyframes floatIt2 {
  0% {
    translate: 0 0;
  }

  50% {
    translate: -15% -15%;
  }

  100% {
    translate: 0 0;
  }
}

@media only screen and (max-width: 920px) {
  .AfterMarketler {
    width: 100%;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #061022;
    color: white;
    padding: 3rem;
    gap: 1rem;
    .partnersLogos {
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      img {
        width: 20%;
      }
    }
    .afterText {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 1rem;

      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5rem;
        margin-bottom: 1rem;
      }
    }
  }
}
