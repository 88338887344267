.Cp {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 3rem;
  text-align: center;
  color: #091a36;
  svg {
    font-size: 5rem;
    color: #091a36;
  }
  img {
    width: 50%;
    border-radius: 20px;
  }
  p {
    font-weight: 600;
    background-color: #091a36;
    color: white;
    border-radius: 20px;
    padding: 1rem;
    line-height: 2rem;
  }
  ul {
    background-color: #091a36;
    color: white;
    border-radius: 20px;
    padding: 1rem;
    list-style: inside;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
}
