.Intro{
    width: 100%;
    height: 100%;
    position: relative;
    top: 5rem;
    video{
        width: 100%;
    }

    .intro_text{
      position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      h1{
        
        font-size: 3rem;
        color: #fff;
        // text-shadow: 0 2px 2px #f3c623;
        text-align: center;
        font-weight: 800;
        font-style: Italic;
    }
    }

    



    .mouse {
        width: 50px;
        height: 90px;
        border: 2px solid #ffffff;
        border-radius: 60px;
        position: absolute;
        z-index: 11;
        left: 48%;
        margin-top: -20%;
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          position: absolute;
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
          background-color: #ffffff;
          border-radius: 50%;
          opacity: 1;
          animation: wheel 2s infinite;
          -webkit-animation: wheel 2s infinite;
        }
      }
}

@keyframes wheel {
    to {
      opacity: 0;
      top: 60px;
    }
  }
  
  @-webkit-keyframes wheel {
    to {
      opacity: 0;
      top: 60px;
    }
  }


@media only screen and (max-width: 920px){
    .Intro{
      .intro_text{
        position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        h1{
          
          font-size: 1.5rem;
          color: #fff;
          // text-shadow: 0 2px 2px #f3c623;
          text-align: center;
          font-weight: 800;
          font-style: Italic;
      }
      }
        .mouse {
            margin-top: -15%;
            width: 30px;
            height: 50px;
            &::before {
              width: 6px;
              height: 6px;
            }
          }
    }
}