nav{
    width: 100vw;
    padding: 1.5rem 3rem 1.5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #061022;
    z-index: 5;
    position: fixed;
    top: 0;
    -webkit-box-shadow: 2px 21px 52px -35px rgba(255,238,0,1);
    -moz-box-shadow: 2px 21px 52px -35px rgba(255,238,0,1);
    box-shadow: 2px 21px 52px -35px rgba(255,238,0,1);
    img{
        width: 9rem;
    }
    .hamburger {
        display: none;
        cursor: pointer;
        color: #f3c623;
        svg {
          font-size: 1.5rem;
        }
      }
    .navMenu{
        display: flex;
        justify-content: center;
        align-items: center;
        .menu{
            display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        .flags{
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          span{
            &:hover{
              cursor: pointer;
              opacity: 0.6;
            }
          }
        }
        }
        a{
            color: white;
            text-decoration: none;
        }
        a:hover{
          color: #f3c623;
        }
    }
    .side-menu {
        background-color: #061022;
        position: absolute;
        top: 5rem;
        right: 0;
        padding: 2rem;
        border-radius: 5px 0 0 5px;
        border: 1px solid rgb(0, 0, 0);
        .side-projects {
          font-family: altera;
          font-weight: 700;
        }
        ul {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          list-style: none;
          gap: 0.5rem;
          li {
            a {
              text-decoration: none;
              color: white;
              font-weight: 800;
            }
            &:hover{
                color: #f3c623;
                cursor: pointer;
            }
          }
        }
        .side-menu-list {
          li {
            h2 {
              &:hover {
                opacity: 0.5;
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.5);
              }
            }
            a {
                text-decoration: none;
                color: white;
                font-weight: 800;
                &:hover{
                  color: #f3c623;
                  cursor: pointer;
              }
              }
              
            p {
              &:hover {
                opacity: 0.5;
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.5);
              }
            }
          }
          .flags{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            span{
              &:hover{
                cursor: pointer;
                opacity: 0.6;
              }
            }
          }
        }}
    .dropDownMenu{
        position: absolute;
        z-index: 10;
        right: 17%;
        top: 60%;
        color: white;
        background-color: #061022;
        padding: 1rem 3rem;
        text-align: center;
        border-radius: 20px;
        li{
            margin: 1rem;
            a{
                color: white;
                text-decoration: none;
            }
            a:hover{
              color: #f3c623;
            }
        }
    }
}


@media only screen and (max-width: 920px){
    nav{
        img{
           
        }
        .hamburger {
            display: block;
          }
        .navMenu{
            display: none;
            .menu{
               
            }
        }
    }
}